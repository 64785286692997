import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import useBlogData from "../static-queries/useBlogData"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = () => {
  const [imageShown, setImageShown] = useState(false)

  const blogData = useBlogData()

  const image = useStaticQuery(graphql`
    query getMamaImage {
      imageSharp(fluid: { originalName: { eq: "theresa-risse.jpg" } }) {
        id
        fluid(maxWidth: 600) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="grid grid-cols-12 px-4 lg:px-6 min-h-screen">
        <div className="col-span-12 mt-16 mb-16">
          <h1>Chymeia</h1>
          <h2 className="font-sans">
            ...aus dem Dunkel geborgen - mit Glanz bedacht.
          </h2>
          <div className="relative z-10">
            <span
              role={"presentation"}
              className="cursor-pointer"
              onMouseEnter={() => setImageShown(true)}
              onMouseLeave={() => setImageShown(false)}
              onClick={() => {
                imageShown ? setImageShown(false) : setImageShown(true)
              }}
            >
              Theresa Risse
            </span>{" "}
            - Psychotherapie, Supervision
            <div
              className={[
                "absolute w-48 left-0 transition-all transform-gpu duration-150 p-1 bg-gold origin-top-left shadow-md",
                imageShown
                  ? "z-20 scale-100 opacity-100"
                  : "z-0 scale-0 opacity-0",
              ].join(" ")}
            >
              <Img
                fluid={image.imageSharp.fluid}
                // imgStyle={{
                //   transform: imageShown ? "scale(1)" : "scale(0)",
                //   transition: "transform .15s, opacity 0.15s",
                //   transformOrigin: "top left",
                //   opacity: imageShown ? "100%" : "0",
                //   padding: "0.25rem",
                //   background: "url('/images/bg-gold.png')",
                // }}
              />
            </div>
          </div>
        </div>
        {blogData.map(blog => {
          return (
            <Link
              to={`/posts/${blog.node.fields.slug}`}
              className="col-span-12 lg:col-start-4 lg:col-span-9 grid grid-cols-9 auto-rows-min group"
              key={`blogListing-${blog.node.id}`}
            >
              <div className="col-span-full lg:col-span-3 max-h-80">
                <Img
                  fluid={
                    blog.node.frontmatter.einleitungsbild.childImageSharp.fluid
                  }
                  className="w-full h-full"
                />
              </div>
              <div className="col-span-full py-4 lg:py-0 lg:col-span-6 flex flex-row justify-end items-end relative">
                <div
                  className={
                    "invisible lg:visible blog-link-background lg:group-hover:translate-x-0 duration-100 ease-in-out"
                  }
                ></div>
                <div className="flex flex-col relative z-1 lg:px-6 overflow-hidden text-right">
                  {/* <div className="text-gold-600 lg:group-hover:text-gold-200">
                    {blog.node.frontmatter.tag.map(tag => (
                      <span key={`blogListingTag-${blog.node.id}-${tag}`}>
                        {tag}
                      </span>
                    ))}
                  </div> */}
                  <h3 className="m-0 lg:group-hover:text-white break-words min-w-0">
                    {blog.node.frontmatter.uberschrift}
                  </h3>
                  <span className="text-right text-black-700 text-opacity-80 lg:group-hover:text-gold-100">
                    {blog.node.frontmatter.datum}
                  </span>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage
